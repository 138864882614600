<template>
  <div>
      <div class="container">
          <el-card>
              <div slot="header">
                  <h3>湖畔云平台MQTT密码计算</h3>
                  <h3>填入设备信息：</h3>
                </div>
              <div>
                    <div class="box">
                        productKey:<input type="text" id="productKey" name="productKey"></input><br/>
                    </div>
                    <div class="box">
                        deviceID:<input type="text" id="deviceID" name="deviceID"></input><br/>
                    </div>
                    <div class="box">
                        deviceSecret:<input type="text" id="deviceSecret" name="deviceSecret" style="width:300px"></input><br/>
                    </div>           
                    <div class="box">
                        timestamp:<input type="text" id="timestamp" name="timestamp"></input><br>
                    </div>  
                    <!-- <div class="box">
                        clientId:<input type="text" id="clientId" name="clientId" style="width:300px"></input><br/>
                    </div>   -->
                    <div class="box">
                        method:<select id="signMethod" name="signMethod">
                        <option value="1">md5</option>
                        <option value="2" selected>sha1</option>
                        </select><br/><br/>      
                        点击这里得出密码：<button id="submit" name="submit" @click="onSign()">Generate</button>
                        <br/>
                    </div>
                
                    
                   
              </div>
          </el-card>
          <el-card>
                    <div slot="header"><h3>计算结果：</h3></div>
                    <div>
                         <!-- 点击这里求结果：<input type="button" name="Submit" value="按钮" onclick="show();" /> -->
                    <div id="show"></div>
                    <div id="show1"></div>
                    <div id="show2"></div>
                    <div id="show4"></div>
                    <div id="show3"></div>
                    用户密码password:  <br/><input type="text" id="password" name="password" style="width:600px"></input>
                    </div>
            </el-card>    
        </div>
        <Footer></Footer>
  </div>
</template>

<script>
import {md5} from '../assets/js/md5'
import {sha1} from '../assets/js/sha1'
import Footer from '@/components/footer'
export default {
data() {
return {
 
}
},
mounted() {
 
},
methods: {
    onSign() {
        var pk = document.getElementById("productKey").value;
        var dn = document.getElementById("deviceID").value;
        var ds = document.getElementById("deviceSecret").value;
        var ts = document.getElementById("timestamp").value;
        // var ci = document.getElementById("clientId").value;
        var sm = document.getElementById("signMethod").value;
        var pw = document.getElementById("password");
        if (pk == null || pk == "" || dn == null || dn == "" 
                || ds == null || ds == "" ) {//|| ci == null || ci == ""
            alert("productKey,deviceID,deviceSecret,clientId can not be empty");
            return;
        }
        var contentStr =  deviceSecret.value + ",ClientId" + deviceID.value+ "DeviceID" + deviceID.value + "ProductKey" + productKey.value + "Timestamp" + timestamp.value;
        var sign="";
        if (sm ==1) {
            sign = md5(contentStr);
        } else if (sm ==2) {
            sign = sha1(contentStr);
        } else {
            alert("method is invalid");
        }
        pw.value = sign;
		
		var a1=document.getElementById("productKey");
        var a2=document.getElementById("deviceID");
        var a3=document.getElementById("Port");
        // var a4=document.getElementById("clientId");
        var a5=document.getElementById("signMethod");
        var a6=document.getElementById("timestamp");

        var show=document.getElementById("show");
        show.innerHTML="域名Domain Name:"+"<br/>mqtt.nnhpiot.com";
        show1.innerHTML="端口Port:"+"<br/>1883";
        show2.innerHTML="客户端编号ClientId(MQTT):"+"<br/>"+deviceID.value+"|securemode=3,signmethod="+signMethod.value+",timestamp="+timestamp.value+"|";

        // n19wsEZnTMt|Securemode=3,Signmethod=1,Timestamp=10|
       	// show4.innerHTML="客户端编号ClientId(MQTTS TLS):"+"<br/>"+clientId.value+"|securemode=2,signmethod="+signMethod.value+",timestamp="+timestamp.value+"|";
        show3.innerHTML="用户名Username:"+"<br/>"+deviceID.value+"&"+productKey.value;
    }
},
components:{
    Footer
},
}
</script>

<style scoped>
.container{
    margin-top: 50px;
    font-size: 20px;
    line-height: 30px;
    min-height: 800px;
}
.container input{
    font-size: 20px;
}
.box{
    margin: 1rem 1rem 1rem 1rem;
}
</style>